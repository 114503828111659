$(function() {
   $("#carouselBrand").slick({
      infinite: true,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      speed: 1000,
      responsive: [{
         breakpoint: 1200,
         settings: {
            slidesToShow: 4,
         }
      }, {
         breakpoint: 991,
         settings: {
            slidesToShow: 3,
         }
      }, {
         breakpoint: 768,
         settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
         }
      }, {
         breakpoint: 360,
         settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
         }
      }]
   });
});
